<template>
  <div class="ele-activities">
    <div class="top-btns">
      <el-button type="primary" size="mini" @click="doAdd">新增活动</el-button>
    </div>

    <!-- 查询表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="标题">
          <el-input v-model="searchForm.titleLike" clearable />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="searchForm.descriptionLike" clearable />
        </el-form-item>
        <el-form-item label="状态">
          <code-domain-select
            v-model="searchForm.flag"
            code-domain="domain.flag"
          ></code-domain-select>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker
            v-model="startDateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker
            v-model="endDateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <common-table
      ref="activitiesTable"
      :columns="tableConfig.columns"
      :search-params="searchForm"
      :api-key="tableConfig.apiKey"
      :auto-load="true"
      :options="tableConfig.options"
    ></common-table>

    <!-- 编辑弹窗 -->
    <el-drawer
      :title="editDialogInfo.title"
      :visible.sync="editDialogInfo.show"
      :close-on-click-modal="false"
      destroy-on-close
      size="50%"
      @opened="handlerDialogOpend"
      @closed="handlerDialogClosed"
    >
      <template #title>
        <div style="width: 100%">
          <el-button
            type="primary"
            size="mini"
            :loading="editDialogInfo.saving"
            @click="saveTemplateData"
            >保存</el-button
          >
        </div>
      </template>

      <ele-activity-edit-form
        ref="editDialog"
        :action="editDialogInfo.action"
        :id="editDialogInfo.id"
      />
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Activities",
  data() {
    return {
      editDialogInfo: {
        action: "add",
        id: null,
        saving: false,
        show: false,
      },
      startDateRange: [],
      endDateRange: [],
      searchForm: {
        flag: 1,
        orderBy: "show_order",
      },
      tableConfig: {
        apiKey: "fw-ele-activity-list",
        options: [
          {
            label: "修改",
            handler: (row) => {
              this.doEdit(row);
            },
          },
          {
            label: "启用",
            handler: (row) => {
              this.enableActivity(row);
            },
          },
          {
            label: "停用",
            handler: (row) => {
              this.disableActivity(row);
            },
          },
        ],
        columns: [
          {
            prop: "activityId",
            label: "活动ID",
          },
          {
            prop: "title",
            label: "活动名称",
          },
          {
            prop: "description",
            label: "描述",
          },
          {
            prop: "startDate",
            label: "开始日期",
            formatter: (row, column, cellVal) => {
              return cellVal.substring(0, 10);
            },
          },
          {
            prop: "endDate",
            label: "结束日期",
            formatter: (row, column, cellVal) => {
              return cellVal.substring(0, 10);
            },
          },
          {
            prop: "commissionRate",
            label: "佣金比例",
            formatter: (row, column, cellVal) => {
              return cellVal + "%";
            },
          },
          {
            prop: "showOrder",
            label: "显示顺序",
          },
        ],
      },
    };
  },
  methods: {
    async saveTemplateData() {
      let formData = this.$refs.editDialog.getData();
      this.editDialogInfo.saving = true;
      try {
        await this.$http.doApi("fw-ele-activity-save", {}, formData);
        this.$message.success("保存成功");
        this.editDialogInfo.show = false;
        this.$refs.activitiesTable.refreshCurrent();
      } catch (e) {
        console.log(e);
      } finally {
        this.editDialogInfo.saving = false;
      }
    },
    handlerDialogOpend() {
      this.$refs.editDialog.opened();
    },
    handlerDialogClosed() {
      this.editDialogInfo.id = null;
      this.editDialogInfo.action = "add";
    },
    doSearch() {
      this.$refs.activitiesTable.refresh();
    },
    doAdd() {
      this.editDialogInfo.show = true;
    },
    async doEdit({ id }) {
      this.editDialogInfo.title = "修改活动信息";
      this.editDialogInfo.id = id;
      this.editDialogInfo.show = true;
    },
    async disableActivity({ id }) {
      try {
        await this.$http.doApi("fw-ele-activity-disable", { id });
        this.$message.success("删除成功");
        this.$refs.activitiesTable.refreshCurrent();
      } catch (e) {
        console.log(e);
      }
    },
    async enableActivity({ id }) {
      try {
        await this.$http.doApi("fw-ele-activity-enable", { id });
        this.$message.success("启用成功");
        this.$refs.activitiesTable.refreshCurrent();
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    startDateRange(newVal) {
      if (newVal && newVal.length > 0) {
        this.searchForm.startDateGte = newVal[0];
        this.searchForm.startDateLte = newVal[1];
      } else {
        delete this.searchForm.startDateGte;
        delete this.searchForm.startDateLte;
      }
    },
    endDateRange(newVal) {
      if (newVal && newVal.length > 0) {
        this.searchForm.endDateGte = newVal[0];
        this.searchForm.endDateLte = newVal[1];
      } else {
        delete this.searchForm.endDateGte;
        delete this.searchForm.endDateLte;
      }
    },
  },
  components: {
    "ele-activity-edit-form": () => import("./ActivityEditForm.vue"),
  },
};
</script>

<style lang="less">
.ele-activities {
  .top-btns {
    padding: 1rem;
  }
  .search-form {
    padding: 1rem;
  }
}
</style>